/**
 * Container component for the Download CSV modal.
 */
import { useState } from 'react';
import type { Location } from '../../types/Location';
import { getDetections } from '../../utils/api';
import { downloadCsv } from '../../utils/csv';
import { getRows, getColumns } from '../../utils/detections';
import { useData } from '../../hooks/useData';
import Datepicker from '../time/Datepicker';
import DropdownSelectMultiple from '../dropdowns/DropdownSelectMultiple';
import ModalBasic from './ModalBasic';
import StandardButton from '../buttons/StandardButton';

export default function DownloadModalCSV({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { userLocations, toggled } = useData();
  const dropDownOptions = userLocations.map((location) => {
    return {
      id: location.id,
      value: location.displayName,
    };
  });

  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);

  // Handle selecting a date.
  function handleSelectedDateChange(selectedDates: Date[]): void {
    setSelectedDates(selectedDates);
  }

  // Handle selecting a location.
  function handleSelectedLocationChange(id: string): void {
    const location = userLocations.find((location) => location.id === id);
    if (location !== undefined) {
      setSelectedLocations([...selectedLocations, location]);
    }
  }

  async function handleDownload(): Promise<void> {
    // Fetch the data.
    const detections = await getDetections(
      selectedDates[0],
      selectedDates[1],
      selectedLocations.map((location) => location.id),
      toggled === 'drive-thru'
    );

    const rows = getRows(detections);
    let cols = getColumns(detections);
    if (toggled !== 'drive-thru') {
       cols = [...cols, {
        label: 'Valid Time',
        key: 'validTimeSpent',
        type: 'seconds',
      }];
    }

    const rowData = rows.map((row) => {
      const rowArray: any[] = [];
      for (const col of cols) {
        rowArray.push(row[col.key as keyof typeof row]);
      }
      return rowArray;
    });

    // Structure the CSV.
    downloadCsv(rowData, cols);

    // Close the modal.
    setIsOpen(false);
  }

  const dropDownSelected = selectedLocations.map((location) => {
    return {
      id: location.id,
      value: location.displayName,
    };
  });

  return (
    <ModalBasic title="Download CSV" isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex flex-col gap-4 min-w-96 p-4">
        {/* Date Selector */}
        <div className="flex flex-col gap-2">
          <div>Select Date Range</div>
          <Datepicker
            initDates={selectedDates}
            handleDateChange={handleSelectedDateChange}
          />
        </div>
        {/* Location Selector */}
        <div className="flex flex-col gap-2">
          <div>Select Locations</div>
          <DropdownSelectMultiple
            options={dropDownOptions}
            selected={dropDownSelected}
            handleChange={handleSelectedLocationChange}
            title={`${selectedLocations.length} Locations`}
          />
        </div>
        {/* Confirm Button */}
        <StandardButton
          onClick={() => {
            handleDownload().catch((error) => {
              console.error('Error downloading CSV', error);
            });
          }}
        >
          Download CSV
        </StandardButton>
      </div>
    </ModalBasic>
  );
}
