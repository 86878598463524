import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig('./src/css/tailwind.config.js');
};

const tailwindColors = tailwindConfig().theme?.colors;

export const colors = [
tailwindColors.amber[400],
tailwindColors.blue[400],
tailwindColors.cyan[400],
tailwindColors.emerald[400],
tailwindColors.fuchsia[400],
tailwindColors.lime[400],
tailwindColors.orange[400],
tailwindColors.rose[400],
tailwindColors.sky[400],
tailwindColors.violet[400],
tailwindColors.red[400],
tailwindColors.green[400],
tailwindColors.purple[400],
tailwindColors.pink[400],
tailwindColors.indigo[400],
tailwindColors.yellow[400],
tailwindColors.teal[400],
tailwindColors.amber[500],
tailwindColors.blue[500],
tailwindColors.cyan[500],
tailwindColors.emerald[500],
tailwindColors.fuchsia[500],
tailwindColors.lime[500],
tailwindColors.orange[500],
tailwindColors.rose[500],
tailwindColors.sky[500],
tailwindColors.violet[500],
tailwindColors.red[500],
tailwindColors.green[500],
tailwindColors.purple[500],
tailwindColors.pink[500],
tailwindColors.indigo[500],
tailwindColors.yellow[500],
tailwindColors.teal[500],
tailwindColors.amber[600],
tailwindColors.blue[600],
tailwindColors.cyan[600],
tailwindColors.emerald[600],
tailwindColors.fuchsia[600],
tailwindColors.lime[600],
tailwindColors.orange[600],
tailwindColors.rose[600],
tailwindColors.sky[600],
tailwindColors.violet[600],
tailwindColors.red[600],
tailwindColors.green[600],
tailwindColors.purple[600],
tailwindColors.pink[600],
tailwindColors.indigo[600],
tailwindColors.yellow[600],
tailwindColors.teal[600],
tailwindColors.blue[700],
tailwindColors.cyan[700],
tailwindColors.emerald[700],
tailwindColors.fuchsia[700],
tailwindColors.lime[700],
tailwindColors.orange[700],
tailwindColors.rose[700],
tailwindColors.sky[700],
tailwindColors.violet[700],
tailwindColors.red[700],
tailwindColors.green[700],
tailwindColors.purple[700],
tailwindColors.pink[700],
tailwindColors.indigo[700],
tailwindColors.yellow[700],
tailwindColors.teal[700],

];

export const mutedColors = [
  tailwindColors.amber[100],
  tailwindColors.blue[100],
  tailwindColors.cyan[100],
  tailwindColors.emerald[100],
  tailwindColors.fuchsia[100],
  tailwindColors.lime[100],
  tailwindColors.orange[100],
  tailwindColors.rose[100],
  tailwindColors.sky[100],
  tailwindColors.violet[100],
  tailwindColors.red[100],
  tailwindColors.green[100],
  tailwindColors.purple[100],
  tailwindColors.pink[100],
  tailwindColors.indigo[100],
  tailwindColors.yellow[100],
  tailwindColors.teal[100],
  tailwindColors.amber[200],
  tailwindColors.blue[200],
  tailwindColors.cyan[200],
  tailwindColors.emerald[200],
  tailwindColors.fuchsia[200],
  tailwindColors.lime[200],
  tailwindColors.orange[200],
  tailwindColors.rose[200],
  tailwindColors.sky[200],
  tailwindColors.violet[200],
  tailwindColors.red[200],
  tailwindColors.green[200],
  tailwindColors.purple[200],
  tailwindColors.pink[200],
  tailwindColors.indigo[200],
  tailwindColors.yellow[200],
  tailwindColors.teal[200],
  tailwindColors.amber[300],
  tailwindColors.blue[300],
  tailwindColors.cyan[300],
  tailwindColors.emerald[300],
  tailwindColors.fuchsia[300],
  tailwindColors.lime[300],
  tailwindColors.orange[300],
  tailwindColors.rose[300],
  tailwindColors.sky[300],
  tailwindColors.violet[300],
  tailwindColors.red[300],
  tailwindColors.green[300],
  tailwindColors.purple[300],
  tailwindColors.pink[300],
  tailwindColors.indigo[300],
  tailwindColors.yellow[300],
  tailwindColors.teal[300],
];

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);
