'use client';

import { useState, useEffect } from 'react';
import { type RegionsStats } from '@/types/RegionsStats';
import { Bar, BarChart, CartesianGrid, type TooltipProps, XAxis, YAxis } from 'recharts';
import { formatSeconds } from '@/utils/time';
import { type LocationData } from '@/types/TrendLineData';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
} from '@/components/ui/chart';

// Data type interfaces
interface RegionsChartData {
  locationData: LocationData[];
  barData: BarData[];
}

interface BarData {
  region: string;
  [id: string]: number | string; // locationId-time for service time at region
}

const chartConfig = {
  region: {
    label: 'Region',
  },
  time: {
    label: 'Service Time'
  },
} satisfies ChartConfig;

// Data type converters
function orderRegionNames(data: RegionsChartData): RegionsChartData {
  // Hardcoded for miguel's
  const orderMap: Record<string, number> = {
    pre: 1,
    order: 2,
    payment: 3,
    leave: 4,
    pull: 5
  };
  data.barData.sort((a, b) => {
      return (orderMap[a.region] ?? Infinity) - (orderMap[b.region] ?? Infinity);
  });
  return data;
}

export function RegionsGraph({
    regionsData,
    colorMap
  }: {
    regionsData: RegionsStats[] | null;
    colorMap: Record<string, string>;
  }): JSX.Element {
  const [chartData, setChartData] = useState<RegionsChartData | null>(null);
  const [regionNames, setRegionNames] = useState<string[]>();
  const [flatData, setFlatData] = useState<any[]>();

  const createFlatBarData = (locationData: LocationData[], names: string[]): any[] => {
    const flatData = [];
    locationData.forEach((loc) => {
      names.forEach(rName => {
        flatData.push({
          dataKey: `${rName}`,
          key: `${loc.locationName}`,
          name: `${loc.locationName}`,
          fill: loc.color
        });
      });
    });
    return flatData;
  };

  // Convert RegionsStats[] to RegionsChartData[]
  function convertToRegionsChartData(rawData: RegionsStats[] | null): RegionsChartData {
    const newData = {
      locationData: [],
      barData: [],
    } as RegionsChartData;
    const dateMap = new Map();

    if (rawData) {
      // Add location data
      newData.locationData = rawData.map((data) => ({
        locationName: data.locationName,
        locationId: data.locationId,
        color: colorMap[data.locationId]
      }));

      // Add bar data for this location
      rawData.forEach(loc => {
        loc.regions.forEach(r => {
          const serviceTimeKey = `${loc.locationName}`;
          if (!dateMap.has(r.key)) {
            dateMap.set(r.key, {
              [serviceTimeKey]: r.timeSpent,
            });
          } else {
            const existingData = dateMap.get(r.key);
            existingData[serviceTimeKey] = r.timeSpent;
          }
        });
      });
    }

    newData.barData = Array.from(dateMap.entries()).map(([region, data]) => ({
      region,
      ...data,
    }));

    return orderRegionNames(newData);
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload }) => {
    if (active && payload?.length) {
      const sortedPayload = [...payload].sort(
        (b, a) => (b.payload[a.dataKey] ?? 0) - (a.payload[b.dataKey] ?? 0)
      );
      return (
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '5px',
          borderRadius: '4px',
          fontSize: 'small'
        }}>
          {sortedPayload.map(pl => (
            <div key={pl.dataKey} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '1em',
                  height: '1em',
                  backgroundColor: pl.color,
                  marginRight: '0.5em'
                }}
              />
              <p>{`${pl.name} ${pl.payload.region} - Time: ${formatSeconds(pl.payload[pl?.dataKey || 0])}`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };
  useEffect(() => {
    // Create chart data
    const newChartData = convertToRegionsChartData(regionsData);
    // Set chart data
    setChartData(newChartData);
    // Set region names
    const names = newChartData.barData.map(r => r.region);
    setRegionNames(names);
    // Set flat version of bar data
    setFlatData(createFlatBarData(newChartData.locationData, names));
  }, [regionsData]);

  if (!chartData || !regionNames) {
    return (<div>{null}</div>);
  }

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <Card>
        <CardHeader>
          <CardTitle>Stations</CardTitle>
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData.barData } barSize={100}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="region"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis
                label={{ value: 'Time Spent', angle: -90, position: 'insideLeft' }}
                tickFormatter={(value) => formatSeconds(value)}
                />
              <ChartTooltip
                cursor={false}
                content={<CustomTooltip />}
              />
              {[...new Map(flatData?.map(item => [item.key, item])).values()].map(data => (
            <Bar
              dataKey={data.key}
              key={data.dayPart}
              fill={data.fill}
              radius={4}
              name={data.name}
            />
          ))}
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
