
import { useState, useEffect, SetStateAction } from "react";
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { ReservedKeyword } from "../../interfaces/reservedKeyword";
import DropdownFranchise from "../../components/dropdowns/DropdownFranchise";
import DropdownLocationMultiple from "../../components/dropdowns/DropdownLocationMultiple";
import { useData } from "../../hooks/useData";
import VideoPlayerKinesis from '../../components/cameras/VideoPlayerKinesis';
import LiveButton from '../../components/cameras/LiveButton';
import { getMothershipMetrics, getMothershipLogs } from '../../utils/api';
import { useCamera } from '../../hooks/useCamera';

const Mothership = () => {
  const [allLocationMetrics, setAllLocationMetrics] = useState<Map<string, any> | null>(null); // Track the metrics in state
  const [loading, setLoading] = useState(true); // Track loading state

  const {
    selectedCamera,
    setSelectedCamera,
    visibleCameras,
    setVisibleCameras,
    numCamerasDisplayed,
    setNumCamerasDisplayed,
    setVideoDateTime,
    setIsLive,
    currentPage,
    setTotalPages,
    isDownloading,
    setIsDownloading,
    isGrid,
    kinesisVideo,
    setKinesisVideo,
    availableCameras,
    setAvailableCameras,
    showCvToggle,
    setShowCvToggle,
    showCvStream,
    setShowCvStream,
    showCvDtStream,
    setShowCvDtStream,
    showMonitorStream,
    setShowMonitorStream,
    hasCvStream,
    setHasCvStream,
    hasCvDtStream,
    setHasCvDtStream,
    hasMonitorStream,
    setHasMonitorStream,
    setIsEditing,
    cameraOrder,
    setCameraOrder,
  } = useCamera();

  const reservedKeywords: ReservedKeyword = {
    "blackout": {
      polygonColor: "rgba(0,0,0,1)",
      description: "Reserved keyword that blacks out the polygon. Color cannot be modified"
    },
    "ginger": {
      polygonColor: "rgba(235, 47, 6, 1.0)",
      description: "Reserved keyword that sets the color to an intense tomato red. Color cannot be modified"
    }
  };

  const { selectedFranchise, selectedLocations } = useData();

  // Get AWS credentials on load.
  useEffect(() => {
    async function setUpKinesis(): Promise<void> {
      const userCredentials = await Auth.currentCredentials();
      AWS.config.update({
        region: import.meta.env.VITE_AWS_REGION,
        credentials: Auth.essentialCredentials(userCredentials),
      });
      setKinesisVideo(new AWS.KinesisVideo());
    }
    setUpKinesis().catch((error) => {
      console.error('Error setting up Kinesis', error);
    });
  }, []);

  // Fetch metrics when selectedFranchise or selectedLocations changes
  useEffect(() => {
    if (selectedFranchise !== null && selectedLocations.length > 0) {
      const locationIDs = selectedLocations.map(obj => obj.id);
      console.log('Fetching metrics for locations:', locationIDs);

      // Use async function inside the useEffect to handle the promise properly
      const fetchMetrics = async () => {
        try {
          const locationMetrics = await getMothershipMetrics(selectedFranchise.id, locationIDs); // Fetch metrics for the selected franchise and locations
          console.log('locationMetrics',locationMetrics)
          setAllLocationMetrics(new Map(Object.entries(locationMetrics))); // Set the metrics state
          setLoading(false); // Set loading to false after fetching data
        } catch (error) {
          console.error('Error fetching location metrics:', error);
          setLoading(false); // Stop loading in case of error
        }
      };

      fetchMetrics(); // Call the async function
    }
  }, [selectedFranchise, selectedLocations]); // Ensure selectedLocations and selectedFranchise are dependencies

  return (
    <div className="cont">
      <div className="row">
        <div className="col-10 col-m-10 p-2 mx-auto"></div>
        <div className="flex flex-wrap justify-start gap-2 col-10 col-m-10 p-2 mx-auto">
          {/* Franchise Selector */}
          <DropdownFranchise />
          {/* Location Selector */}
          <DropdownLocationMultiple />
          <LiveButton />
        </div>
      </div>

      {/* Video Players Grid */}
      <div className={isGrid ? 'grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4' : 'grid grid-cols-1 gap-4'}>
        {/* Display Loading Indicator or Video Player */}
        {loading ? (
          <div>Loading metrics...</div> // Show loading message while fetching data
        ) : (
          selectedLocations.map((location) => {
            // Safely access the metrics for each location
            const metrics = allLocationMetrics ? allLocationMetrics.get(location.id) : null; // Ensure safe access
            if (!metrics) {
              return (
                <VideoPlayerKinesis
                  key={location.id}
                  location={location}
                  camera="MONITOR"
                  metrics={null}  // Passing null or an empty object if no metrics are available
                  franchise={selectedFranchise}
                />
              );
            }

            return (
              <VideoPlayerKinesis
                key={location.id}
                location={location}
                camera="MONITOR"
                metrics={metrics}  // Pass metrics if available
                franchise={selectedFranchise}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default Mothership;
