/**
 * Utility functions for working with tables.
 */

import type { TableColumn } from '../types/TableColumn';

/**
 * Sorts the data by the given column and sort direction.
 * @param data The data to sort.
 * @param column The column to sort by.
 * @param sortDirection The sort direction, 1 or -1.
 * @returns The sorted data.
 */
export function sortDataByColumn(
  data: any[],
  column: TableColumn,
  sortDirection: number
): any[] {
  if (data.length === 0) {
    return data;
  }
  const newData = [...data];
  newData.sort((a, b) => {
    if (column.type === 'date' || column.type === 'date-local' || column.type === 'timeOpen' || column.type === 'timeClose'
    ) {
      const dateA = new Date(a[column.key] as string | number | Date);
      const dateB = new Date(b[column.key] as string | number | Date);
      return (dateA.getTime() - dateB.getTime()) * sortDirection;
    } else if (column.type === 'string') {
      return (
        (a[column.key].toLowerCase() < b[column.key].toLowerCase() ? -1 : 1) *
        sortDirection
      );
    }
    if (column.key === 'averageServiceTime') {
      if (a[column.key] === 0) {
        return 1 * sortDirection;
      } else if (b[column.key] === 0) {
        return -1 * sortDirection;
      }
      return (a[column.key] - b[column.key]) * sortDirection;
    }
    return (a[column.key] - b[column.key]) * sortDirection;
  });
  return newData;
}

/**
 * Get the maximum numerical value for the given column.
 * @param data The data to search.
 * @param column The column to search.
 * @returns The maximum value for the given column.
 */
export function getMaxValueForColumn(data: any[], column: TableColumn): number {
  let bestValue = -Infinity;
  data.forEach((row) => {
    if (row[column.key] > bestValue) {
      bestValue = row[column.key];
    }
  });
  return bestValue;
}

/**
 * Get the minimum numerical value for the given column.
 * @param data The data to search.
 * @param column The column to search.
 * @returns The minimum value for the given column.
 * */
export function getMinValueForColumn(data: any[], column: TableColumn): number {
  let bestValue = Infinity;
  data.forEach((row) => {
    if (row[column.key] < bestValue) {
      bestValue = row[column.key];
    }
  });
  return bestValue;
}

/**
 * Calculate region order for tables
 * @param cols unsorted column names as TableColumn type
 * @returns sorted column names
 */
export function getRegionOrder(cols: TableColumn[]): TableColumn[] {
  // set up column groups
  const pres: TableColumn[] = [];
  const orders: TableColumn[] = [];
  const payments: TableColumn[] = [];
  const pickups: TableColumn[] = [];
  const leaves: TableColumn[] = [];
  const park: TableColumn[] = [];
  const pullForwards: TableColumn[] = [];
  const remainder: TableColumn[] = [];

  // add columns to column groups
  cols.forEach((c) => {
    if (c.key.includes('pre')) {
      pres.push(c);
    } else if (c.key.includes('order')) {
      orders.push(c);
    } else if (c.key.includes('payment')) {
      payments.push(c);
    } else if (c.key.includes('pickup')) {
      pickups.push(c);
    } else if (c.key.includes('leave')) {
      leaves.push(c);
    } else if (c.key === 'pull_forward') {
      park.push(c);
    } else if (c.key.includes('pull')) {
      pullForwards.push(c);
    } else {
      remainder.push(c);
    }
  });

  // sort column groups
  pres.sort((a, b) => a.key.localeCompare(b.key));
  orders.sort((a, b) => a.key.localeCompare(b.key));
  payments.sort((a, b) => a.key.localeCompare(b.key));
  pickups.sort((a, b) => a.key.localeCompare(b.key));
  leaves.sort((a, b) => a.key.localeCompare(b.key));
  pullForwards.sort((a, b) => a.key.localeCompare(b.key));
  remainder.sort((a, b) => a.key.localeCompare(b.key));

  const res = [
    ...pres,
    ...orders,
    ...payments,
    ...pickups,
    ...leaves,
    ...park,
    ...pullForwards,
    ...remainder
  ];

  return res;
}
