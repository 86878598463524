/**
 * Presentational button component for refreshing data.
 */

export default function DateChangeButton({
    handleDateChange,
    dataCy = 'date-change-button',
    text = 'Last X Days',
    isDisabled = true
  }: {
    handleDateChange: () => void;
    dataCy?: string;
    text?: string;
    isDisabled?: boolean
  }): JSX.Element {
    return (
      <button
        style={{ maxHeight: '2.5em' }}
        onClick={handleDateChange}
        data-cy={dataCy}
        className={`button bg-transparent border border-blue-400 ${
          isDisabled ? 'bg-gray-200 text-gray-400 border-gray-200' : 'hover:bg-blue-400 text-blue-400 hover:text-white border-blue-400'
      } rounded py-1 px-2 text-xs`}
      disabled={isDisabled}
      >
        {text}
      </button>
    );
  }
