/**
 * Container component for the download video modal.
 */
import { useState } from 'react';
import { Endpoint, KinesisVideoArchivedMedia } from 'aws-sdk';
import { getTimezoneOffset } from 'date-fns-tz';
import download from 'downloadjs';
import { useData } from '../../hooks/useData';
import { useCamera } from '../../hooks/useCamera';
import { toast } from 'sonner';

import ModalBasic from './ModalBasic';
import CameraSelector from '../cameras/CameraSelector';
import Datepicker from '../time/Datepicker';
import Flatpickr from 'react-flatpickr';
import type { Hook } from 'flatpickr/dist/types/options';
import { CUSTOM_VIDEO_LOCATIONS } from '@/constants';
import { getVideo } from '@/utils/video';
import { Toaster } from '../ui/sonner';
import moment from 'moment';

export default function DownloadModalContainer({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { selectedLocation } = useData();
  const { kinesisVideo, selectedCamera } = useCamera();
  const [canDownload, setCanDownload] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());
  const millisecondInDay = 60000 * 30;

  // Format the date and time for the video.
  function formatDateTime(date: Date, time: Date): Date {
    if (selectedLocation === null) {
      return date;
    }

    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    const localOffset = -1 * date.getTimezoneOffset() * 60 * 1000;
    const baseOffset = getTimezoneOffset(selectedLocation.timezone);
    const adjusted = new Date(date.valueOf() - (baseOffset - localOffset));
    return adjusted;
  }

  // Handle downloading the video.
  async function handleDownload(): Promise<void> {
    if (
      selectedCamera === null ||
      kinesisVideo === undefined ||
      !canDownload ||
      selectedLocation === null
    ) {
      console.log('Cannot download video.');
      return;
    }

    console.log(selectedCamera);

    setCanDownload(false);
    const startDate = formatDateTime(selectedDate, startTime);
    const endDate = formatDateTime(selectedDate, endTime);
    const streamName = `${selectedLocation.id}_${selectedCamera.id}`;

    if (!CUSTOM_VIDEO_LOCATIONS.includes(selectedLocation.id)) {
      // Get endpoint.
      const endpointObject = await kinesisVideo
        .getDataEndpoint({
          StreamName: streamName,
          APIName: 'GET_HLS_STREAMING_SESSION_URL',
        })
        .promise()
        .catch((err) => {
          console.log('Error getting data endpoint.');
          console.warn(err);
          setCanDownload(true);
        });

      if (endpointObject?.DataEndpoint === undefined) {
        setCanDownload(true);
        return;
      }

      // Define options.
      const options = {
        StreamName: streamName,
        ClipFragmentSelector: {
          FragmentSelectorType: 'SERVER_TIMESTAMP',
          TimestampRange: {
            StartTimestamp: startDate,
            EndTimestamp: endDate,
          },
        },
      };

      // Handle the download.
      const kinesisVideoArchivedMedia = new KinesisVideoArchivedMedia({
        region: import.meta.env.VITE_AWS_REGION,
        endpoint: new Endpoint(endpointObject.DataEndpoint),
      });

      kinesisVideoArchivedMedia.getClip(options, (err, data) => {
        if (err !== null && err !== undefined) {
          console.log('Error downloading video.');
          console.warn(err);
          setCanDownload(true);
          return;
        }

        const blob = new Blob([data.Payload as BlobPart], {
          type: 'video/mp4',
        });
        const month = selectedDate.getMonth() + 1;
        const fileMonth = month < 10 ? `0${month}` : month;
        const fileDay =
          selectedDate.getDate() < 10
            ? `0${selectedDate.getDate()}`
            : selectedDate.getDate();

        const fileName = `video-${selectedDate.getFullYear()}-${fileMonth}-${fileDay}-${startTime.getHours()}H${startTime.getMinutes()}M.mp4`;

        download(blob, fileName, 'video/mp4');
        setCanDownload(true);
      });
    } else {
      console.log('Doing new video download pipeline');

      await getVideo(selectedLocation.id, selectedCamera.id, startDate, moment(endDate).subtract(1, 'minutes').toDate())
      .then(() => {
        toast('Downloaded video.');
      }
      )
      .catch((err) => {
        console.log('Error getting data endpoint.');
        console.warn(err);
        toast('Failed to download', {
          description: 'Camera ' + selectedCamera.displayName + ' ' + startDate.toDateString() + ' to ' + endDate.toDateString(),
          action: {
            label: 'Ok',
            onClick: () => { console.log('Ok'); },
          },
        });
        setCanDownload(true);
      });

      setCanDownload(true);
    }
  }

  const flatpickrStartTimeOptions = {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'h:i K',
    defaultDate: startTime,
  };

  const onStartTimeChange: Hook = (selectedDates, dateStr, instance) => {
    setStartTime(selectedDates[0]);
  };

  const flatpickrEndTimeOptions = {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'h:i K',
    defaultDate: endTime,
  };

  const onEndTimeChange: Hook = (selectedDates, dateStr, instance) => {
    setEndTime(selectedDates[0]);
  };

  if (selectedLocation === null) {
    return <></>;
  }

  return (
    <ModalBasic
      title="Download Video"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dataCy="modal-download-video"
    >
      <Toaster/>
      <div className="flex flex-col gap-4 min-w-96 p-4">
        <p>
          Select the video date and time you would like to download for{' '}
          {selectedLocation.displayName}. You can download at most 30 minutes at
          a time.
        </p>
        <div className="w-full">
          <p className="mb-2">Select Camera</p>
          <CameraSelector />
        </div>
        <div className="w-full">
          <p className="mb-2">Select Date</p>
          <Datepicker
            handleDateChange={(selectedDates: Date[]) => {
              setSelectedDate(selectedDates[0]);
            }}
            initDates={[selectedDate]}
            mode="single"
            data-cy="flatpickr-date"
          />
        </div>
        <div className="w-full">
          <p className="mb-2">Select Start Time</p>
          <Flatpickr
            className="form-input dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium"
            options={flatpickrStartTimeOptions}
            onChange={onStartTimeChange}
            value={startTime}
            data-cy="flatpickr-start-time"
          />
        </div>
        <div className="w-full">
          <p className="mb-2">Select End Time</p>
          <Flatpickr
            className="form-input dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium"
            options={flatpickrEndTimeOptions}
            onChange={onEndTimeChange}
            value={endTime}
            data-cy="flatpickr-end-time"
          />
        </div>

        <div className="flex justify-end">
          <button
            className="text-red-500 background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            className={`${
              (canDownload && selectedCamera !== null && selectedCamera.id != '-1' && startTime < endTime && (endTime.getTime() - startTime.getTime() <= millisecondInDay)) ? 'bg-green-500 active:bg-green-600' : 'bg-gray-600 active:bg-red-600'
            } text-white font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
            type="button"
            disabled={!canDownload || (selectedCamera !== null && selectedCamera.id == '-1') || (startTime >= endTime) || (endTime.getTime() - startTime.getTime() > millisecondInDay)}
            onClick={() => {
              handleDownload().catch((err) => {
                console.log(err);
              });
            }}
          >
            {!canDownload && 'Downloading'}
            {canDownload && 'Download'}
          </button>

        </div>
      </div>

    </ModalBasic>
  );
}
