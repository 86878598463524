import Flatpickr from 'react-flatpickr';
import { useEffect, useState } from 'react';
import DateChangeButton from '../buttons/DateChangeButton';
import DropdownFull from '../dropdowns/DropdownFull';

function DatepickerWithDefaults({
  initDates,
  handleDateChange,
  mode = 'range',
}: {
  initDates: Date[];
  handleDateChange: (selectedDates: Date[]) => void;
  mode?: 'single' | 'multiple' | 'range' | 'time';
}): JSX.Element {
  const today = new Date();
  const oneWeekAgo = new Date();
  const oneMonthAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 7);
  oneMonthAgo.setDate(today.getDate() - 30);

  const [selectedDates, setSelectedDates] = useState<Date[]>(initDates);

  const [isWeekDisabled, setIsWeekDisabled] = useState(false);
  const [isMonthDisabled, setIsMonthDisabled] = useState(false);
  const [isTodayDisabled, setIsTodayDisabled] = useState(false);

  useEffect(() => {
    if (selectedDates.length === 0) {
      return;
    }
    const isCurrentWeek = selectedDates[0].getFullYear() === oneWeekAgo.getFullYear() &&
    selectedDates[0].getMonth() === oneWeekAgo.getMonth() &&
    selectedDates[0].getDate() === oneWeekAgo.getDate() &&
    selectedDates[1].getFullYear() === today.getFullYear() &&
    selectedDates[1].getMonth() === today.getMonth() &&
    selectedDates[1].getDate() === today.getDate();

    const isLast30 = selectedDates[0].getFullYear() === oneMonthAgo.getFullYear() &&
    selectedDates[0].getMonth() === oneMonthAgo.getMonth() &&
    selectedDates[0].getDate() === oneMonthAgo.getDate() &&
    selectedDates[1].getFullYear() === today.getFullYear() &&
    selectedDates[1].getMonth() === today.getMonth() &&
    selectedDates[1].getDate() === today.getDate();

    const isToday = selectedDates[0].getFullYear() === today.getFullYear() &&
    selectedDates[0].getMonth() === today.getMonth() &&
    selectedDates[0].getDate() === today.getDate() &&
    selectedDates[1].getFullYear() === today.getFullYear() &&
    selectedDates[1].getMonth() === today.getMonth() &&
    selectedDates[1].getDate() === today.getDate();

    setIsWeekDisabled(isCurrentWeek);
    setIsMonthDisabled(isLast30);
    setIsTodayDisabled(isToday);
  }, [selectedDates]);

  function setLastWeek(): void {
    setSelectedDates([oneWeekAgo, today]);
    handleDateChange([oneWeekAgo, today]);
  }

  function setLastMonth(): void {
    setSelectedDates([oneMonthAgo, today]);
    handleDateChange([oneMonthAgo, today]);
  }

  function setToday(): void {
    setSelectedDates([today, today]);
    handleDateChange([today, today]);
  }

  return (
    <div className="relative" data-cy="datepicker">

      <Flatpickr
        value={initDates}
        className="form-input pl-9 dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium w-[15.5rem]"
        onChange={(dates: Date[]) => {
          const shouldChange = (mode === 'single') || (dates.length > 1);
          if (shouldChange) {
            setSelectedDates(dates); handleDateChange(dates);
          }
           }}
        options={{
          mode,
          dateFormat: 'M j, Y',
          minDate: new Date('2020-01-01'),
          maxDate: new Date(),
        }}
      />
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
        <svg
          className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 ml-3"
          viewBox="0 0 16 16"
        >
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
      <DateChangeButton handleDateChange={setToday} text={'Today'} isDisabled={isTodayDisabled}/>

      {mode !== 'single' && (
      <>
        <DateChangeButton handleDateChange={setLastWeek} text={'7 days'} isDisabled={isWeekDisabled} />
        <DateChangeButton handleDateChange={setLastMonth} text={'30 days'} isDisabled={isMonthDisabled} />
      </>
      )}
    </div>
  );
}

export default DatepickerWithDefaults;
